.login {
  width: 70%;
  height: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

form {
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 50%;
  margin: 1rem;
}

.epic-name {
  width: 80%;
  margin-bottom: 3rem;
  font-size: 3rem;
  padding: 1rem;
}

input {
  height: 3rem;
  text-align: center;
  margin: 1rem;
  font-family: 'Burbank Big Cd Bk';
  font-size: 1.5rem;
  border-radius: 1rem;
  border: none;
}

.login-button:hover {
  background: linear-gradient(#EDD63A, #EBB62E);
  border-radius: 1rem;
  color: #0A3C9F;
  border: 0.1rem solid #0A3C9F;
}

.error {
  font-size: 1.5rem;
  margin: 0px;
}