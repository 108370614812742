.yellow-gradient {
  background: linear-gradient(#EDD63A, #EBB62E);
  border-radius: 1rem;
  color: #0A3C9F;
}

.blue-font {
  color: #0A3C9F;
}

.text-drop-shadow {
  text-shadow: 0.1rem 0.1rem 0.2rem rgba(0, 0, 0, 0.3);
}

.box-shadow {
  box-shadow: 0.1rem 0.1rem 0.2rem rgba(0, 0, 0, 0.3);
}

.blue-gradient {
  background: linear-gradient(#024BDE, #4528A4);
  color: #FFF6B8;
  border-radius: 1rem;
}