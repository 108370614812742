@font-face {
  font-family: 'Burbank Big Cd Bk';
  src: url('../public/assets/BurbankBigCondensed-Black.woff2') format('woff2'),
      url('../public/assets/BurbankBigCondensed-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

body {
  background: radial-gradient(circle at center, #06B4F9 0, #009CF3, #024BDE);
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Burbank Big Cd Bk';
  font-weight: 900;
  font-style: normal;
  font-weight: 900;
  font-style: normal;
  font-display: swap;
  height: 100vh;
  color: white;
}
