.stats {
  height: 37rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 650px) {
  .stats {
    height: 100%;
  }
}