.news-item {
  height: 13rem;
  width: 30rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem;
  padding-bottom:1rem;
}

.news-image {
  height: 6.5rem;
  border-radius: 1rem;
}

.news-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.news-body {
  display: flex;
  margin-left: 1rem;
  margin-right: 1rem;
  overflow: scroll;
}


.news-title {
  padding: 0.6rem;
  margin: 1rem;
}

.star {
  font-size: 2rem;
  margin: 0px;
  color: #334B79;
  padding: 0.6rem;
}

.star:hover {
  cursor: pointer;
  color: white;
}

.news-text {
  color: black;
  font-size: 1.2rem;
  margin: 0px;
  margin-right: 1rem;
}

.star-favorite {
  color: #ED112B;
}

@media screen and (max-width: 600px) {
  .news-item {
    height: auto;
  }

  .news-text {
    margin: 0.5rem;
  }
}