.App {
  text-align: center;
}

header {
  display: flex;
  align-items: center;
  padding: 1rem;
}

h1 {
  font-size: 3rem;
  margin: 0px;
}

nav {
  padding: 1rem;
}

a {
  color: white;
  text-decoration: none;
  margin: 3rem;
  font-size: 2rem;
  padding: 0.5rem;
}

a:hover {
  cursor: pointer;
  background: linear-gradient(#024BDE, #4528A4);
  color: #FFF6B8;
  border-radius: 1rem;
}

.site-name {
  margin: 0px;
}

.site-name:hover {
  background: transparent;
  animation: wiggle 2s;
}

@keyframes wiggle {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

@media screen and (max-width: 830px) {
  a {
    margin: 0.02rem;
    font-size: 1.2rem;
  }

  h1 {
    font-size: 2rem;
  }

  nav {
    padding: 0.5rem;
  }
}