.inactive-button {
  background: transparent;
  color: #033BAB;
}

.username {
  margin: 0px;
  margin-top: 1rem;
  width: 50%;
  font-size: 2rem;
  padding: 1rem;
}

.card-header {
  margin: 0px;
  margin-bottom: 0.5rem;
  font-size: 2rem;
}

.stats-text {
  font-size: 1.2rem;
  margin: 0.5rem;
}

.stats-display-component {
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stats-display {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stats-container {
  height: 90%;
  width: 90%;
  display: flex;
  margin-bottom: 1rem;
  justify-content: center;
}

.score-and-ranking {
  display: flex;
  flex-direction: column;
  width: 30%;
  margin: 1rem;
  height: 90%;
}

.score {
  padding: 1rem;
  width: 90%;
  height: 50%;
}

.ranking {
  margin-top: 1rem;
  padding: 1rem;
  width: 90%;
  height: 50%;
}

.hits {
  margin: 1rem;
  padding: 1rem;
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.misc {
  margin: 1rem;
  padding: 1rem;
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mode-button {
  margin: 1rem;
}

.try-again-button {
  margin: 1rem;
  padding: 1rem;
}

.try-again-button:hover {
  background: linear-gradient(#EDD63A, #EBB62E);
  border-radius: 1rem;
  color: #0A3C9F;
}

@media screen and (max-width: 650px) {
  .stats-container {
    flex-direction: column;
  }

  .stats-display-component {
    height: 100%;
  }

  .score-and-ranking {
    width: 90%;
  }

  .hits,
  .misc {
    width: 80%;
  }

  .username,
  .mode-button {
    font-size: 1.3rem;
    margin: 0.3rem;
  }
  .card-header {
    font-size: 1.5rem;
  }
}