.item-container {
  display: flex;
  flex-wrap: wrap;
	justify-content: center;
	align-items: flex-start;
}

.shop-update {
  font-size: 1rem;
  margin: 0px;
}

.bundle {
  display: flex;
  flex-direction: column;
}

.bundle-items {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
	justify-content: center;
	align-items: flex-start;
}

.bundle-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.bundle-name {
  font-size: 2rem;
}

/* style this button better lol */
.filter-button-group {
  display: flex;
  justify-content: center;
  margin: 1rem;
}

.filter-button {
  margin: 0.5rem;
  border-radius: 1rem;
}

.mobile-filter-show {
  display: none;
} 

.hide {
  display: none;
}

.mobile-filter-button-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 700px) {
  .shop-update {
    font-size: 1.5rem;
  }
  .filter-button-group {
    flex-direction: column;
  }
  .mobile-filter-show {
    display: block;
  }
}

@media screen and (min-width: 699px) {
  .hide {
    display: block;
  }
}